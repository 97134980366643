import { DataColumns } from '../DataColumns/DataColumns';
import { TenantFilterCell, GridDateCell } from '../../../../Core/Components/Filter/FilterElements';
import {
  MultiViewCalenderFilterCell,
  DateRangeFilterCell,
} from 'smart-react';
/**
 * Generates a list of columns for a grid.
 * @param {object} options - The options for the grid.
 * @returns {Array} - The list of grid columns.
 */
export const GridColumnsList = async ({
  editScreen,
  dateRangefilter,
  setDateRangeFilter,
}) => {
  return DataColumns({
    editScreen,
    TenantFilterCell,
    DateRangeFilterCell,
    dateRangeFilter: dateRangefilter,
    setDateRangeFilter,
    GridDateCell,
    MultiViewCalenderFilterCell,
  });
};

export default GridColumnsList;
