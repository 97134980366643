import React, { useEffect, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import {
  DropDownInput,
  SliderCard,
  TextInput,
  InputSwitch,
  TextAreaInput,
} from 'smart-react';
import { Loader } from 'smart-react';
import {
  LookUp_Mode,
  Lookup_Selection_Mode,
} from '../../../../constants/applicationConstants';
import AppFlowLookupSlider from '../Components/ContentSliders/AppFlowLookupSlider';
import { isFromValid } from 'smart-react';
import { Error } from '@progress/kendo-react-labels';
import { Field, Form } from '@progress/kendo-react-form';
import AppFlowContentSilder from '../../AppFlow/Components/ContentSlider/AppFlowContentSilder';
import { listFlows } from '../../AppFlow/Services/AppFlowService';
import { listSteps } from '../Services/AppFlowStepsService';
import { getLookupByType } from '../../RunSetArguments/Services/RunSetArgumentService';
import getTokensFromStorage from '../../../../Utils/Auth/AuthToken';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import DataFieldContentSlider from '../../../Core/Components/DataFieldContentSlider/DataFieldContentSlider';
import DataField from '../../../Core/Components/DataFieldContentSlider/DataField';
/**
 * AddAppFlowStep component
 * @param {Object} props - Component props
 * @param {boolean} props.show - Flag to show or hide the slider
 * @param {Object} [props.selectedParent] - The selected parent application flow step
 * @param {Function} props.setShowSlider - Callback to toggle slider visibility
 * @param {Array} props.stepTypes - List of step types for dropdown
 * @param {Function} props.onSave - Callback to save the application flow step
 * @param {boolean} props.isSubmitted - Submission state of the form
 * @param {boolean} props.loading - Loading state for the component
 */
const AddAppFlowStep = ({
  show,
  selectedParent,
  selectedOption,
  setSelectedOption,
  setShowSlider,
  stepTypes,
  flow,
  onSave,
  isSubmitted,
  editMode,
}) => {
  const requiredFields = ['UcOssiAfStepId'];
  const [applicationFlows, setApplicationFlows] = useState([]);
  const [steps, setSteps] = useState([]);
  const [specialCodes, setSpecialCodes] = useState([]);
  const [selectedAppFlow, setSelectedAppFlow] = useState(null);
  const [selectedAppFlowStep, setSelectedAppFlowStep] = useState(null);
  const [selectedSpecialCode, setSelectedSpecialCode] = useState(null);
  const [appFlowSlideShow, setAppFlowSlideShow] = React.useState(false);
  const [selectedAFLookupItem, setSelectedAFLookupItem] = React.useState(null);
  const [showDFModal, setShowDFModal] = React.useState(false);
  const [showError, setShowError] = useState(false);
  const [loader, setLoader] = useState(false);
  const { accessToken } = getTokensFromStorage();
  const [data, setData] = React.useState({
    UcOssiExecCntExpr: '',
    UcOssiArgsDisabledFlg: '',
    UcOssiStepVerArgsDisabledFlg: '',
    UcOssiDescr: '',
    UcOssiExecCntArg: '',
    UcOssiPreCondExpr: '',
    UcOssiValExpr: '',
    UcOssiStepRunTillExpr: '',
    UcOssiPostExpr: '',
    UcOssiAfStepId: '',
  });
  const [editorField, setEditorField] = React.useState({
    name: '',
    value: '',
    label: '',
    title: '',
  });
  let selectedUcOssiAfStepId = null;

  /**
   * handle Input Change
   * @e object
   */
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? (checked ? 1 : 0) : value;
    setData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  // Function to validate the form and toggle error visibility
  const validateField = (formData) => {
    const isValid = isFromValid({ formData, requiredFields });
    setShowError(!isValid);
    return isValid;
  };

  const resetFields = () => {
    setSelectedOption(null);
    setData({
      UcOssiExecCntExpr: '',
      UcOssiArgsDisabledFlg: '',
      UcOssiStepVerArgsDisabledFlg: '',
      UcOssiDescr: '',
      UcOssiExecCntArg: '',
      UcOssiPreCondExpr: '',
      UcOssiValExpr: '',
      UcOssiStepRunTillExpr: '',
      UcOssiPostExpr: '',
      UcOssiAfStepId: '',
    });
  };
  /**
   * Determine the step ID based on the selected option.
   * @param {Object} selectedOption - The selected option.
   * @param {Object} selectedAppFlow - The selected app flow.
   * @param {Object} selectedAppFlowStep - The selected app flow step.
   * @param {Object} selectedSpecialCode - The selected special code.
   * @returns {string|null} - The determined UcOssiAfStepId.
   */
  const getUcOssiAfStepId = (
    selectedOption,
    selectedAppFlow,
    selectedAppFlowStep,
    selectedSpecialCode,
  ) => {
    if (selectedOption?.Value === 'APPFLOW' && selectedAppFlow) {
      return selectedAppFlow.uc_ossi_af_id;
    } else if (selectedOption?.Value === 'DEFAFSTP' && selectedAppFlowStep) {
      return selectedAppFlowStep.UcOssiAfStepId;
    } else if (selectedOption?.Value === 'PREDEFLST' && selectedSpecialCode) {
      return selectedSpecialCode.Value;
    }
    return null;
  };

  useEffect(() => {
    const fetchDataBasedOnSelection = async () => {
      setLoader(true);
      try {
        if (selectedOption?.Value === 'APPFLOW') {
          await fetchApplicationFlows();
        } else if (selectedOption?.Value === 'DEFAFSTP') {
          await fetchSteps();
        } else if (selectedOption?.Value === 'PREDEFLST') {
          await fetchSpecialCodes();
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setLoader(false);
    };

    if (selectedOption) {
      fetchDataBasedOnSelection();
    }
  }, [selectedOption]);

  /**
   * Save the selected flow step
   */
  const handleSave = async () => {
    const selectedUcOssiAfStepId = getUcOssiAfStepId(
      selectedOption,
      selectedAppFlow,
      selectedAppFlowStep,
      selectedSpecialCode,
    );
    const formData = {
      ...data,
      UcOssiArgsDisabledFlg: data?.UcOssiArgsDisabledFlg === true ? 1 : 0,
      UcOssiStepVerArgsDisabledFlg:
        data?.UcOssiStepVerArgsDisabledFlg === true ? 1 : 0,
      UcOssiAfStepId: selectedUcOssiAfStepId ?? null,
      ...(selectedParent && { UcOssiAfDtlSeq: selectedParent.UcOssiAfDtlSeq }),
    };
    if (!editMode && !validateField(formData)) return;
    setLoader(true); // Show loader at the start
    try {
      await onSave(formData); // Wait for the onSave action to complete
    } catch (error) {
      console.error('Error during save:', error); // Handle save error
    } finally {
      setLoader(false); // Hide loader after save is complete
      setShowError(false);
    }
  };

  React.useEffect(() => {
    if (!editMode) {
      resetFields();
      setSelectedOption(null); // Ensure the dropdown resets
    }
  }, [editMode]);

  // Handle reset when selectedAFLookupItem changes
  React.useEffect(() => {
    if (selectedAFLookupItem) {
      setSelectedAppFlow(selectedAFLookupItem[0]);
    }
  }, [selectedAFLookupItem]);

  React.useEffect(() => {
    const initializeFormData = async () => {
      if (editMode) {
        // Populate fields in edit mode
        const selectedStepType = stepTypes.find(
          (step) => step.Value === selectedParent?.StepType,
        );
        setSelectedOption(selectedStepType);

        if (selectedParent?.UcOssiAfStepId) {
          switch (selectedStepType?.Value) {
            case 'APPFLOW':
              setSelectedAppFlow({
                uc_ossi_af_id: selectedParent?.UcOssiAfStepId,
              });
              break;
            case 'DEFAFSTP':
              setSelectedAppFlowStep({
                UcOssiAfStepId: selectedParent?.UcOssiAfStepId,
              });
              break;
            case 'PREDEFLST':
              const filteredSpecialCode = specialCodes.find(
                (code) => code.Value === selectedParent?.UcOssiAfStepId,
              );
              setSelectedSpecialCode(filteredSpecialCode);
              break;
            default:
              break;
          }
        }
        // Pre-fill other form fields
        setData({
          UcOssiExecCntExpr: selectedParent.UcOssiExecCntExpr || '',
          UcOssiArgsDisabledFlg: selectedParent.UcOssiArgsDisabledFlg || '',
          UcOssiStepVerArgsDisabledFlg:
            selectedParent.UcOssiStepVerArgsDisabledFlg || '',
          UcOssiDescr: selectedParent.UcOssiDescr || '',
          UcOssiExecCntArg: selectedParent.UcOssiExecCntArg || '',
          UcOssiPreCondExpr: selectedParent.UcOssiPreCondExpr || '',
          UcOssiValExpr: selectedParent.UcOssiValExpr || '',
          UcOssiStepRunTillExpr: selectedParent.UcOssiStepRunTillExpr || '',
          UcOssiPostExpr: selectedParent.UcOssiPostExpr || '',
        });
      }
    };
    initializeFormData();
  }, [editMode, selectedParent, specialCodes, stepTypes]);

  /**
   * Used to show/hide test case lookup slide
   */
  const handleAppFlowSlide = () => {
    if (appFlowSlideShow) {
      setAppFlowSlideShow(false);
    } else {
      setAppFlowSlideShow(true);
    }
  };

  // Update the error state dynamically when selections are made
  React.useEffect(() => {
    if (selectedOption && selectedUcOssiAfStepId) {
      setShowError(false);
    }
  }, [selectedOption, selectedUcOssiAfStepId]);

  /**
   * Fetches all application flows, filters out a specific flow by uc_ossi_af_id, and updates the state.
   * @async
   */
  const fetchApplicationFlows = async () => {
    const limit = 5000;
    try {
      const response = await listFlows({ accessToken, limit });
      // Apply filtering to exclude the flow with the specific uc_ossi_af_id
      const filteredFlows =
        response?.flows?.filter(
          (item) => item.uc_ossi_af_id !== flow?.uc_ossi_af_id,
        ) || [];
      setApplicationFlows(filteredFlows);
    } catch (error) {
      setApplicationFlows([]); // Handle error gracefully
    }
  };

  /**
   * Fetches steps from the API.
   * @async
   */
  const fetchSteps = async () => {
    const limit = 5000;
    const steps = await listSteps({ accessToken, limit });
    setSteps(steps.steps);
  };

  /**
   * Fetches special codes from the API.
   * @async
   */
  const fetchSpecialCodes = async () => {
    const SpecialCodes = await getLookupByType({
      type: 'App.Autest.AFActions',
    });
    setSpecialCodes(SpecialCodes.Payload);
  };

  return (
    <>
      <AppFlowContentSilder show={show}>
        <SliderCard
          handleSlide={() => setShowSlider(false)}
          title={`${editMode ? 'Edit' : 'Add'} Application Flow Step`}
          className="test-doc-slider small-slider"
        >
          <SliderCard.Body>
            {loader && <Loader />}
            {selectedParent && !editMode && (
              <div className="!k-mt-5 !k-mb-1">
                <strong>Parent:</strong> <p>{selectedParent?.UcOssiAfStepId}</p>
              </div>
            )}
            <Form
              onSubmit={onSave}
              render={(formRenderProps) => (
                <div className="card-content content-slider-form">
                  <div className="single-field-row">
                    <div className="field-wrapper">
                      <DropDownInput
                        label="Select Type"
                        textField="DisplayName"
                        dataItemKey="Value"
                        dataSet={stepTypes}
                        value={
                          selectedOption ?? {
                            DisplayName: 'Select Step Type...',
                            Value: '',
                          }
                        }
                        defaultValue={{
                          DisplayName: 'Select Step Type...',
                          Value: '',
                        }}
                        handleChange={(e) => {
                          setSelectedOption(e.value);
                        }}
                        filterable={true}
                        requiredField={true}
                        renderTypes={editMode ? 'ReadOnly' : ''}
                      />
                    </div>
                  </div>
                  {selectedOption?.Value === 'APPFLOW' && (
                    <div
                      className={`single-field-row ${!editMode ? ' k-d-flex k-align-items-end ' : ''}`}
                    >
                      <div className="field-wrapper">
                        <DropDownInput
                          label="Select Application Flow:"
                          textField="uc_ossi_af_id"
                          dataItemKey="uc_ossi_af_id"
                          className="testcase-dropdown k-align-self-end !k-mt-1"
                          defaultValue={{
                            uc_ossi_af_id: 'Select Application Flow...',
                          }}
                          dataSet={applicationFlows}
                          value={
                            selectedAppFlow ?? {
                              uc_ossi_af_id: 'Select Application Flow...',
                            }
                          }
                          handleChange={(e) => {
                            setSelectedAppFlow(e.value);
                          }}
                          requiredField={true}
                          renderTypes={editMode ? 'ReadOnly' : ''}
                        />
                      </div>
                      {!editMode && (
                        <Button
                          themeColor={'primary'}
                          className={`k-mx-1 !k-mt-1 !k-mr-0 ${!editMode ? 'k-mb-1.5' : ''}`}
                          icon="search"
                          onClick={() => setAppFlowSlideShow(true)}
                        />
                      )}
                    </div>
                  )}

                  {selectedOption?.Value === 'DEFAFSTP' && (
                    <div className="single-field-row">
                      <div className="field-wrapper">
                        <DropDownInput
                          label="Select User Step"
                          textField="UcOssiAfStepId"
                          dataItemKey="UcOssiAfStepId"
                          dataSet={steps}
                          defaultValue={{
                            UcOssiAfStepId: 'Select Application Flow Step...',
                          }}
                          value={
                            selectedAppFlowStep ?? {
                              UcOssiAfStepId: 'Select Application Flow Step...',
                            }
                          }
                          handleChange={(e) => {
                            setSelectedAppFlowStep(e.value);
                          }}
                          requiredField={true}
                          renderTypes={editMode ? 'ReadOnly' : ''}
                        />
                      </div>
                    </div>
                  )}
                  {selectedOption?.Value === 'PREDEFLST' && (
                    <div className="single-field-row">
                      <div className="field-wrapper">
                        <DropDownInput
                          label="Select Special Code"
                          textField="Value"
                          dataItemKey="DisplayName"
                          dataSet={specialCodes}
                          filterable={true}
                          value={
                            selectedSpecialCode ?? {
                              Value: 'Select Code...',
                            }
                          }
                          defaultValue={{
                            Value: 'Select Code...',
                          }}
                          handleChange={(e) => {
                            setSelectedSpecialCode(e.value);
                          }}
                          requiredField={true}
                          renderTypes={editMode ? 'ReadOnly' : ''}
                        />
                      </div>
                    </div>
                  )}
                  {!editMode && showError && (
                    <Error>This field cannot be empty</Error>
                  )}
                  <div className="single-field-row">
                    <div className="field-wrapper data-field-wrapper">
                      <Field
                        key={'UcOssiDescr'}
                        id={'UcOssiDescr'}
                        name={'UcOssiDescr'}
                        data={data?.UcOssiDescr}
                        onChange={handleInputChange}
                        label={'Description'}
                        type={'text'}
                        component={TextAreaInput}
                        row={2}
                        optional={false}
                      />
                      <span
                        onClick={() => {
                          setShowDFModal(true);
                          setEditorField({
                            name: 'UcOssiDescr',
                            value: '',
                            title: 'Description',
                          });
                        }}
                        className="open-editor-icon"
                      >
                        <FontAwesomeIcon
                          className="icon-full-screen k-mr-1"
                          icon={faExpand}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="single-field-row">
                    <div className="field-wrapper">
                      <Field
                        key={'UcOssiExecCntExpr'}
                        id={'UcOssiExecCntExpr'}
                        name={'UcOssiExecCntExpr'}
                        data={data?.UcOssiExecCntExpr}
                        onChange={handleInputChange}
                        label={'Expression for number of executions'}
                        type={'text'}
                        component={TextInput}
                        optional={false}
                      />
                    </div>
                  </div>
                  <div className="single-field-row">
                    <div className="field-wrapper">
                      <Field
                        key={'UcOssiExecCntArg'}
                        id={'UcOssiExecCntArg'}
                        name={'UcOssiExecCntArg'}
                        data={data?.UcOssiExecCntArg}
                        onChange={handleInputChange}
                        label={'Execution Count Argument'}
                        type={'text'}
                        component={TextInput}
                        optional={false}
                      />
                    </div>
                  </div>
                  <div className="single-field-row">
                    <div className="field-wrapper">
                      <Field
                        key={'UcOssiPreCondExpr'}
                        id={'UcOssiPreCondExpr'}
                        name={'UcOssiPreCondExpr'}
                        data={data?.UcOssiPreCondExpr}
                        onChange={handleInputChange}
                        label={'Pre Condition Expression'}
                        type={'text'}
                        component={TextInput}
                        optional={false}
                      />
                    </div>
                  </div>
                  <div className="single-field-row">
                    <div className="field-wrapper">
                      <Field
                        key={'UcOssiValExpr'}
                        id={'UcOssiValExpr'}
                        name={'UcOssiValExpr'}
                        data={data?.UcOssiValExpr}
                        onChange={handleInputChange}
                        label={'Value Expression'}
                        type={'text'}
                        component={TextInput}
                        optional={false}
                      />
                    </div>
                  </div>
                  <div className="single-field-row">
                    <div className="field-wrapper">
                      <Field
                        key={'UcOssiStepRunTillExpr'}
                        id={'UcOssiStepRunTillExpr'}
                        name={'UcOssiStepRunTillExpr'}
                        data={data?.UcOssiStepRunTillExpr}
                        onChange={handleInputChange}
                        label={'Run Until Expression'}
                        type={'text'}
                        component={TextInput}
                        optional={false}
                      />
                    </div>
                  </div>
                  <div className="single-field-row">
                    <div className="field-wrapper">
                      <Field
                        key={'UcOssiPostExpr'}
                        id={'UcOssiPostExpr'}
                        name={'UcOssiPostExpr'}
                        data={data?.UcOssiPostExpr}
                        onChange={handleInputChange}
                        label={'Post Expression'}
                        type={'text'}
                        component={TextInput}
                        optional={false}
                      />
                    </div>
                  </div>
                  <div className="single-field-row">
                    <div className="field-wrapper">
                      <Field
                        name="UcOssiArgsDisabledFlg"
                        component={InputSwitch}
                        label="Arguments Disabled"
                        onLabel="Yes"
                        offLabel="No"
                        id="UcOssiArgsDisabledFlg"
                        checked={data?.UcOssiArgsDisabledFlg}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="single-field-row">
                    <div className="field-wrapper">
                      <Field
                        name="UcOssiStepVerArgsDisabledFlg"
                        component={InputSwitch}
                        label="Step Version Arguments Disabled"
                        onLabel="Yes"
                        offLabel="No"
                        id="UcOssiStepVerArgsDisabledFlg"
                        checked={data?.UcOssiStepVerArgsDisabledFlg}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="data-field-section">
                    <DataFieldContentSlider show={showDFModal}>
                      <DataField
                        handleSlide={() => {
                          setShowDFModal(false);
                        }}
                        editorField={editorField}
                        setFormData={setData}
                        formData={data}
                      />
                    </DataFieldContentSlider>
                  </div>
                </div>
              )}
            />
          </SliderCard.Body>
          <SliderCard.Footer>
            {isSubmitted === false ? (
              <>
                <Button
                  className={'small-slider-action'}
                  icon="cancel"
                  onClick={() => setShowSlider(false)}
                  type="button"
                >
                  Cancel
                </Button>
                <Button themeColor="primary" icon="save" onClick={handleSave}>
                  Save
                </Button>
              </>
            ) : (
              <Button onClick={() => setShowSlider(false)} type="button">
                Close
              </Button>
            )}
          </SliderCard.Footer>
        </SliderCard>
      </AppFlowContentSilder>
      <AppFlowLookupSlider
        show={appFlowSlideShow}
        handleSlide={handleAppFlowSlide}
        setSelectedLookupItems={setSelectedAFLookupItem}
        selectedLookupItems={selectedAFLookupItem}
        lookupSelectionMode={Lookup_Selection_Mode.single}
        mode={LookUp_Mode.LookUp}
      />
    </>
  );
};

export default AddAppFlowStep;
