import React, { useEffect, useState } from 'react';
import {
  TreeList,
  mapTree,
  extendDataItem,
} from '@progress/kendo-react-treelist';
import { Loader, ErrorFallback } from 'smart-react';
import '@progress/kendo-theme-default/dist/all.css';
import { ErrorBoundary } from 'react-error-boundary';
import { buildNotification, GenerateNotification } from 'smart-react';
import { Button } from '@progress/kendo-react-buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import AddApplicationFlowStep from './AddAppFlowStep'; // Import the new component
import { getLookupByType } from '../../RunSetArguments/Services/RunSetArgumentService.js';
import {
  createFlowStep,
  deleteFlowStep,
  listAppFlowStepByID,
  updateFlowStep,
} from '../Services/AppFlowStepsService';
import './AppFlowSteps.scss';
import { isFromValid } from 'smart-react';
import {
  CREATE_MESSAGE,
  DELETE_MESSAGE,
  ERROR_MESSAGE,
  UPDATE_MESSAGE,
} from '../../../../constants/notificationMessages';
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from '../../../../constants/eventDataTypes';
import { useAuth } from '../../../Core/Context/AuthContext';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import AppFlowDetailArgumentSlider from '../../AppFlowDetailArguments/Components/ContentSlider/AppFlowDetailArgumentSlider';
import DataColumns from './../Components/DataColumns/DataColumns'; // Import the column function

import { APPFLOWDTL } from '../../../../constants/applicationConstants';
import AddAppFlowSlider from '../Components/ContentSliders/AddAppFlowSlider';

const subItemsField = 'children';
const expandField = 'expanded';

/**
 * ApplicationFlowSteps component.
 * Displays a tree list of application flow steps with functionality to add, delete, and manage child steps.
 *
 * @component
 * @returns {React.ReactElement} The ApplicationFlowSteps component.
 */
const AppFlowSteps = (props) => {
  const [state, setState] = useState({
    data: [],
    expanded: [],
  });
  const { tenantID } = useAuth(); // Get tenantID using Auth Context
  const [showSlider, setShowSlider] = useState(false);
  const [selectedOption, setSelectedOption] = React.useState(null); // Initialize with first step type
  const [stepTypes, setStepTypes] = useState([]);
  const [selectedParent, setSelectedParent] = useState(null);
  const [isSubmitted] = useState(false);
  const [visible, setVisible] = React.useState(false);
  const [isLoader, setIsLoader] = React.useState(false);
  const [isSliderLoader, setIsSliderLoader] = React.useState(false);
  const [handleSlide, setHandleSlide] = React.useState(false);
  const [selectedItemForDeletion, setSelectedItemForDeletion] = useState(null);
  const [argumentsData, setArgumentsData] = useState({}); // State for step arguments
  const [editMode, setEditMode] = useState(false); // State for step arguments
  const [addAppFlowSlideShow, setAddAppFlowSlideShow] = React.useState(false);
  const [formData, setFormData] = React.useState({
    TenantId: tenantID,
    UcOssiAfId: props?.flow?.uc_ossi_af_id,
    UcOssiProdId: 'REDPRAIRIE',
    UcOssiAfStepId: '',
  });
  const requiredFields = ['TenantId', 'UcOssiAfId'];

  useEffect(() => {
    fetchAppFlowTypes();
  }, []);

  useEffect(() => {
    const fetchSteps = async () => {
      if (stepTypes?.length > 0) {
        try {
          await fetchApplicationFlowStepsById(); // Fetch steps after stepTypes are loaded
        } catch (error) {
          console.error('Error fetching steps:', error);
        }
      }
    };
    fetchSteps();
  }, [stepTypes]);

  /**
   * Opens the delete confirmation dialog and sets the selected item.
   * @param {Object} item - The selected tree node to delete.
   */
  const deleteFlowStepDialog = (item) => {
    setSelectedItemForDeletion(item);
    setVisible(true);
  };

  /**
   * handle step argument slider
   * @param item
   * @returns {Promise<void>}
   */
  const fetchArgumentsForStep = async (item) => {
    setArgumentsData(item);
    setHandleSlide(true);
  };

  /**
   * Fetches application flow steps by ID and prepares the data.
   * @async
   */
  const fetchApplicationFlowStepsById = async () => {
    setIsLoader(true);
    const filter = [
      {
        ColumnName: 'UcOssiAfId',
        Op: 'eq',
        ColumnValue: props?.flow.uc_ossi_af_id,
      },
    ];
    const response = await listAppFlowStepByID({
      filter: filter ? filter : [],
      uc_ossi_af_id: props?.flow.uc_ossi_af_id,
    });
    const initialData = prepareData(response.steps);
    if (initialData) {
      setState((prevState) => ({ ...prevState, data: initialData }));
    }
    setIsLoader(false);
  };

  /**
   * Fetches special codes from the API.
   * @async
   */
  const fetchAppFlowTypes = async () => {
    const appFlowTypes = await getLookupByType({
      type: 'App.AuTest.AF.Types',
    });
    setStepTypes(appFlowTypes.Payload);
  };

  /**
   * Handles adding a child step.
   * @param {TreeNode} parentItem - The parent tree node to which a child is added.
   */
  const onAddChild = (parentItem) => {
    setSelectedParent(parentItem);
    setEditMode(false);
    setShowSlider(true);
  };

  /**
   * Handles adding a child step.
   */
  const onEditStep = (parentItem) => {
    setSelectedParent(parentItem);
    setEditMode(true);
    setShowSlider(true);
  };

  /**
   * Handles adding a child step.
   */
  const onEditAppFlow = (parentItem) => {
    setSelectedParent(parentItem);
    setAddAppFlowSlideShow(true);
  };

  /**
   * Used to show/hide test case lookup slide
   */
  const handleAddAppFlowSlide = () => {
    if (addAppFlowSlideShow) {
      setAddAppFlowSlideShow(false);
    } else {
      setAddAppFlowSlideShow(true);
    }
  };

  /**
   * Defines the columns for the AppFlowSteps TreeList by calling the `getAppFlowColumns` function.
   */
  const getColumns = DataColumns({
    parentFlowId: props?.flow?.uc_ossi_af_id,
    onAddChild,
    onEditStep,
    onEditAppFlow,
    deleteFlowStepDialog,
    fetchArgumentsForStep,
  });

  /**
   * Deletes a tree node by ID.
   */
  const onDelete = async () => {
    if (!selectedItemForDeletion) return;
    setVisible(false);
    setIsLoader(true);
    let data = selectedItemForDeletion;
    let response = await deleteFlowStep({
      data,
      moduleName: APPFLOWDTL,
      isServiceWorker: false,
    });
    try {
      if (response?.IsSuccess) {
        GenerateNotification(
          buildNotification(DELETE_MESSAGE),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
      } else {
        GenerateNotification(
          buildNotification(ERROR_MESSAGE),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
      }
    } catch (error) {
      GenerateNotification(
        buildNotification(ERROR_MESSAGE),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
    } finally {
      setSelectedItemForDeletion(null);
      await fetchApplicationFlowStepsById();
      setIsLoader(false);
    }
  };

  /**
   * Adds the `expandField` to the tree data structure.
   * @param {TreeNode[]} dataTree - The data tree to process.
   * @returns {TreeNode[]} The processed tree with expanded state.
   */
  const addExpandField = (dataTree) => {
    return mapTree(dataTree, subItemsField, (item) =>
      extendDataItem(item, subItemsField, {
        [expandField]: state.expanded.includes(item.id),
      }),
    );
  };

  /**
   * Prepares the data for the tree list.
   * @returns {TreeNode[]} The processed tree data.
   */
  const processData = () => {
    return addExpandField(state.data);
  };
  /**
   * Handles tree node expand/collapse changes.
   * @param {Object} e - The event object for expand/collapse.
   */
  const onExpandChange = (e) => {
    const itemId = e.dataItem.id;
    setState((prevState) => ({
      ...prevState,
      expanded: e.value
        ? prevState.expanded.filter((id) => id !== itemId)
        : [...prevState.expanded, itemId],
    }));
  };

  /**
   * Handles the Save button click in the Add Step slider.
   */
  const onSave = async (addFlowStepData) => {
    if (!isFromValid({ formData, requiredFields })) {
      return;
    }
    const { selectedUcOssiAfStepId } = addFlowStepData;

    setIsSliderLoader(true);
    let notificationMetaData = {};
    const data = {
      ...formData,
      ...(selectedParent && {
        UcOssiAfId: selectedParent.UcOssiAfStepId,
      }),
      ...(editMode && {
        UcOssiAfId: selectedParent.UcOssiAfId,
        TenantId: selectedParent.TenantId,
      }),
      UcOssiAfStepId: selectedUcOssiAfStepId,
      ...addFlowStepData,
    };
    let response;
    let method;

    method = editMode ? updateFlowStep : createFlowStep;
    notificationMetaData = editMode ? CREATE_MESSAGE : UPDATE_MESSAGE;

    try {
      response = await method({
        data,
        moduleName: APPFLOWDTL,
        isServiceWorker: false,
      });
      if (!response?.IsSuccess) {
        GenerateNotification(
            buildNotification({
              title: ERROR_MESSAGE?.title,
              description: response.errorMessage,
              style: ERROR_MESSAGE?.style,
            }),
            NOTIFICATION_TYPES.APP,
            EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
      } else {
          GenerateNotification(
            buildNotification(notificationMetaData),
            NOTIFICATION_TYPES.APP,
            EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
          );
        setShowSlider(false);
      }
    } catch (error) {
      GenerateNotification(
        buildNotification(ERROR_MESSAGE),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
    } finally {
      setShowSlider(false);
      await fetchApplicationFlowStepsById();
      setIsSliderLoader(false);
    }
  };

  /**
   * Get the display name of a step type.
   * @param {string} stepType - The step type value.
   * @param {Array} stepTypes - The list of available step types.
   * @returns {string} - The display name for the step type.
   */
  const getStepTypeDisplayName = (stepType, stepTypes) => {
    const found = stepTypes.find((type) => type.Value === stepType);
    return found ? found.DisplayName : 'Unknown Step Type';
  };

  /**
   * Prepare Data for Tree Structure
   * @param data
   * @param parent
   * @returns {*}
   */
  const prepareData = (data, parent = null) => {
    return data?.map((item) => {
      const children = item.ApplicationFlow?.ApplicationFlowSteps
        ? prepareData(item.ApplicationFlow.ApplicationFlowSteps, item) // Pass the current item as the parent
        : [];

      return {
        ...item,
        id: item.UcOssiAfStepId || item.UcOssiAfId,
        hasChildren: children.length > 0,
        parent, // Add the parent reference to the item
        children,
        StepTypeDisplayName: getStepTypeDisplayName(item?.StepType, stepTypes),
      };
    });
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className="container mx-auto">
        <React.Suspense fallback={<Loader />}>
          {visible && (
            <Dialog
              title={'Please confirm'}
              onClose={() => setVisible(!visible)}
            >
              <p
                style={{
                  margin: '25px',
                  textAlign: 'center',
                }}
              >
                Are you sure you want to unlink this flow step?
              </p>
              <DialogActionsBar>
                <button
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                  onClick={() => setVisible(!visible)}
                >
                  No
                </button>
                <button
                  className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
                  onClick={onDelete}
                >
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
          <div className="k-float-right k-p-3 k-border-0 add-app-flow-step-button">
            <Button
              themeColor={'primary'}
              className="k-mx-1 "
              onClick={() => {
                setShowSlider(true);
                setEditMode(false);
                setSelectedOption(null);
                setSelectedParent(null);
              }}
            >
              <FontAwesomeIcon className="k-mr-2" icon={faPlus} /> Add
            </Button>
          </div>
          <div className="k-clear-both" />
          <div className={'grid-wrapper tree-grid-wrapper'}>
            <TreeList
              data={processData()}
              columns={getColumns}
              expandField={expandField}
              subItemsField={subItemsField}
              onExpandChange={onExpandChange}
              style={{
                height: '300px',
                overflow: 'auto',
              }}
              tableProps={{ style: { width: '100%' } }}
            />
          </div>
          {showSlider && (
            <AddApplicationFlowStep
              show={showSlider}
              selectedParent={selectedParent}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              setShowSlider={setShowSlider}
              stepTypes={stepTypes}
              onSave={onSave}
              isSubmitted={isSubmitted}
              formData={formData}
              editMode={editMode}
              isSliderLoader={isSliderLoader}
              flow={props?.flow}
            />
          )}
          {handleSlide && (
            <AppFlowDetailArgumentSlider
              handleSlide={handleSlide}
              setHandleSlide={setHandleSlide}
              flowStep={argumentsData}
            />
          )}
          {addAppFlowSlideShow && (
            <AddAppFlowSlider
              show={addAppFlowSlideShow}
              handleSlide={handleAddAppFlowSlide}
              dataItem={selectedParent}
              setAddAppFlowSlideShow={setAddAppFlowSlideShow}
            />
          )}
        </React.Suspense>
        {isLoader && (
          <div className="loader-wrapper">
            <Loader />
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default AppFlowSteps;
